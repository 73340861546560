import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { IidICB } from "service/OrganizationAdmin/Organization";

interface P {
  item: IidICB;
  index: number;
  onChangeValue: (index: number, key: string, values: string) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
      {/* TIDName */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập TIDName"
            onChange={(e) => onChangeValue(index, "TIDName", e.target.value)}
            defaultValue={item.TIDName}
          />
        </Box>
      </td>
      {/* xIbmXlientSecret */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập xIbmXlientSecret"
            onChange={(e) =>
              onChangeValue(index, "xIbmXlientSecret", e.target.value)
            }
            defaultValue={item.xIbmXlientSecret}
          />
        </Box>
      </td>
      {/* xIbmClietId */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập xIbmClietId"
            onChange={(e) =>
              onChangeValue(index, "xIbmClietId", e.target.value)
            }
            defaultValue={item.xIbmClietId}
          />
        </Box>
      </td>
      {/* providerId */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập providerId"
            onChange={(e) => onChangeValue(index, "providerId", e.target.value)}
            defaultValue={item.providerId}
          />
        </Box>
      </td>
      {/* note */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập note"
            onChange={(e) => onChangeValue(index, "note", e.target.value)}
            defaultValue={item.note}
          />
        </Box>
      </td>
    </>
  );
};

export default RenderRowInput;
