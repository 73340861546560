import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import React, { useEffect, useMemo, useState } from "react";
import {
  IidICB,
  PaymentPartnerDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";
import { omit } from "lodash";

interface IProps {
  listTID: IidICB[];
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
  setListTID: React.Dispatch<React.SetStateAction<IidICB[]>>;
}

const SetUpID = ({
  listTID,
  setListTID,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: IProps) => {
  const [cloneListTID, setCloneListTID] = useState<IidICB[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState("");
  const [isCreateNew, setIsCreateNew] = useState(false);
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const {
    PaymentPartnerUpdate,
    PaymentPartnerCreate,
    PaymentVNPayPartnerDelete,
    PaymentConfigPartnerCreate,
  } = OrganizationAdminService;

  const handleEditRow = (index: number, _id: string) => {
    setIsEdit(true);
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, isEdit: true };
    setIdRowEdit(_id);
    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const onChangeValue = (index: number, key: string, values: string) => {
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, [key]: values };

    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const rowData = cloneListTID.find((_, indx) => indx === index);

    const newArr = [
      ...cloneListTID.slice(0, index),
      ...cloneListTID.slice(index + 1),
    ];

    setCloneListTID(newArr);
    setListTID(newArr);
    paymentPartnerDetail && onFinishRm(rowData._id);
  };

  const onFinishRm = async (id: string) => {
    try {
      const valuePost: any = {
        _idParner: paymentPartnerDetail._id,
        configType: "TID",
        _idConfig: id,
      };

      let response = await PaymentVNPayPartnerDelete(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        valuePost
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        message.success("Xoá TID thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const handleAddRow = () => {
    setCloneListTID([
      ...cloneListTID,
      {
        providerId: "",
        TIDName: "",
        xIbmClietId: "",
        xIbmXlientSecret: "",
        note: "",
        isEdit: true,
      },
    ]);
  };

  const handleConfirm = () => {
    let err = false;
    const listData = cloneListTID.map((e) => ({ ...e, isEdit: false }));
    listData.forEach((e: any) => {
      Object.keys(e).forEach((el: any) => {
        if (e[el] === "") {
          err = true;
        }
      });
    });

    if (!err) {
      setListTID(listData);
      setCloneListTID(listData);
      setIsEdit(false);
      let rowData;
      if (idRowEdit) {
        // update
        rowData = listData.find((e) => e._id === idRowEdit);
      } else {
        // create get last arr
        rowData = listData?.[listData.length - 1];
      }
      rowData = { ...rowData, _id: "" };
      // console.log({ paymentPartnerDetail, rowData, idRowEdit });
      // paymentPartnerDetail &&
        onFinish(omit(rowData, ["TIDStar", "_id"]), idRowEdit);
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  const onFinish = async (rowEdit: IidICB, idRowEdit: string) => {
    try {
      // todo create global config type
      const valuePost: any = {
        TID: [rowEdit].map((e) => {
          const { isEdit, ...rest } = e;
          return { ...rest, TIDStar: 1 };
        }),
      };

      let response: any;
      // tạo mới khi chưa có cấu hình
      if (isCreateNew) {
        response = await PaymentPartnerCreate(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase(),
          valuePost
        );
      }

      if (paymentPartnerDetail) {
        const sendDataForCreat: any = {
          _idParner: paymentPartnerDetail._id,
          configType: "TID",
          TID: [rowEdit].map((e) => {
            const { isEdit, ...rest } = e;
            return { ...rest };
          }),
        };

        const sendDataForUpdate: any = {
          _idParner: paymentPartnerDetail._id,
          configType: "TID",
          TID: cloneListTID.map((e) => {
            const { isEdit, ...rest } = e;
            return { ...rest };
          }),
        };

        if (idRowEdit) {
          // cập nhật cấu hình
          response = await PaymentPartnerUpdate(
            orgCode,
            schoolCode,
            ID.toLocaleUpperCase(),
            sendDataForUpdate
          );
        } else {
          // tạo thêm cấu hình TID
          response = await PaymentConfigPartnerCreate(
            orgCode,
            schoolCode,
            ID.toLocaleUpperCase(),
            sendDataForCreat
          );
        }
      }

      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        setIsCreateNew(false);
        handleGetDetailPaymentParner();
        if (isCreateNew) {
          message.success("Thêm mới TID thành công");
        } else {
          idRowEdit
            ? message.success("Chỉnh sửa TID thành công")
            : message.success("Thêm mới TID thành công");
        }
      } else {
        if (response && validResponse(response)) {
          // !idRowEdit && handleRemoveRow(cloneListTID.length - 1);

          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setCloneListTID(listTID);
    setIsCreateNew(listTID.length === 0);
    // eslint-disable-next-line
  }, [listTID]);

  const handleCancel = () => {
    setIsEdit(false);
    setCloneListTID([...listTID]);
  };

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <Box overflowX={"scroll"}>
        <table className="my-table w-90 max-w-1078">
          <thead>
            <tr>
              <th style={{ width: "" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  TIDName <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>
              <th style={{ width: "" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  xIbmXlientSecret <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>
              <th style={{ width: "" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  xIbmClietId <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>
              <th style={{ width: "" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  providerId <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Ghi chú <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>

              <th style={{ minWidth: "100px" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Hành động
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {cloneListTID.map((e, i) => (
              <tr key={i} className="tr-tid">
                {e.isEdit ? (
                  <RenderRowInput
                    item={e}
                    onChangeValue={onChangeValue}
                    index={i}
                  />
                ) : (
                  <RenderRow
                    item={e}
                    index={i}
                    isEdit={isEdit}
                    paymentPartnerDetail={paymentPartnerDetail}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box className="w-90 max-w-1078">
        {!isEdit && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                // remove id edit
                setIdRowEdit("");
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SetUpID;
