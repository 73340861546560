import { Box, Flex, Text } from "@chakra-ui/react";
import IconEdit from "components/icons/IconEdit";
import IconRemove from "components/icons/IconRemove";
import { useMemo } from "react";
import {
  IidICB,
  PaymentPartnerDetail,
} from "service/OrganizationAdmin/Organization";
import { globalStyles } from "theme/styles";
import { uniq } from "lodash";

interface P {
  item: IidICB;
  index: number;
  isEdit: boolean;
  handleEditRow: (index: number, _id: string) => void;
  handleRemoveRow: (index: number) => void;
  paymentPartnerDetail: PaymentPartnerDetail;
}

const RenderRow = ({
  item,
  handleRemoveRow,
  index,
  handleEditRow,
  isEdit,
  paymentPartnerDetail,
}: P) => {

  const isUsedTID = useMemo(() => {
    const listTID = paymentPartnerDetail?.transactionNavigation?.map(
      (item) => item?.TID?._id
    );
    return uniq(listTID).includes(item?._id);
    // eslint-disable-next-line
  }, [paymentPartnerDetail]);

  return (
    <>
      {/* TIDName */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.TIDName}
        </Text>
      </td>
      {/* xIbmXlientSecret */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.xIbmXlientSecret}
        </Text>
      </td>
      {/* xIbmClietId */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.xIbmClietId}
        </Text>
      </td>
      {/* providerId */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.providerId}
        </Text>
      </td>
      {/*  Ghi chú */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.note}
        </Text>
      </td>

      <td>
        {!isEdit && (
          <Flex pl="0" justifyContent={"end"} alignItems={"center"}>
            {item._id && !isUsedTID && (
              <Box
                pr="2"
                onClick={() => handleRemoveRow(index)}
                cursor="pointer"
              >
                <IconRemove />
              </Box>
            )}
            <Box
              pr="20px"
              cursor="pointer"
              onClick={() => handleEditRow(index, item._id)}
            >
              <IconEdit />
            </Box>
          </Flex>
        )}
      </td>
    </>
  );
};

export default RenderRow;
