import { Box, Flex, Text } from "@chakra-ui/react";
import { Empty, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ClientCode,
  IidSHB,
  ITransactionNavigation,
  ItypeFee,
  PaymentPartnerDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import {
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  setSchoolDetail,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import SetUpID from "./Components/SetUpTID";
import TransactionNavigation from "./Components/TransactionNavigation";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import TabsPanel from "./Components/TabsPanel";
import * as Sentry from "@sentry/browser";

export type ITID = {
  TIDStar: number;
  TIDName: string;
  tmnCode: string;
  tmnHashSecret: string;
  bankAccountNumber: string;
  note?: string;
  isEdit: boolean;
  _id?: string;
};

const SetUpPayment = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const history = useHistory();
  const { schoolDetail } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);
  const [listTID, setListTID] = useState<IidSHB[]>([]);
  const [activeBtn, setActiveBtn] = useState(1);
  const [typeFee, setTypeFee] = useState<ItypeFee[]>([]);
  const [listClientCode, setListClientCode] = useState<ClientCode[]>([]);

  const [transactionNavigation, setTransactionNavigation] = useState<
    ITransactionNavigation[]
  >([]);
  const [paymentPartnerDetail, SetPaymentPartnerDetail] = useState<
    PaymentPartnerDetail | undefined
  >(undefined);
  const {
    GetDetailShool,
    PaymentPartnerDetail,
    GetSchoolTypeFee,
    GetListClientCode,
  } = OrganizationAdminService;

  const handleGetDetail = useCallback(async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetDetailShool(orgCode, schoolCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        dispatch(setSchoolDetail(response.data));
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  }, [GetDetailShool, dispatch, orgCode, schoolCode]);

  const handleGetSchoolTypeFee = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolTypeFee(orgCode, schoolCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data
      ) {
        res.data.data[0] && setTypeFee(res.data.data[0].typeFee);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetDetailPaymentParner = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await PaymentPartnerDetail(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase()
      );
      //@ts-ignore
      console.log(res.data.code)
      //@ts-ignore
      console.log(res.data.data)
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data
      ) {
        SetPaymentPartnerDetail(res.data.data);
        setListTID(res.data.data.TID as any as IidSHB[]);
        res.data.data?.transactionNavigation &&
          setTransactionNavigation(res.data.data.transactionNavigation);
      } else {
        // console.log("lỗi lè");
        // message.error("Có lỗi xảy ra");
        // Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetListClientCode = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetListClientCode(orgCode, schoolCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data
      ) {
        setListClientCode(...res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    !schoolDetail && handleGetDetail();
    handleGetDetailPaymentParner();
    handleGetSchoolTypeFee();
    handleGetListClientCode();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/school-information/${schoolDetail.schoolCode}`,
        },
        {
          name: "Đối tác TGTT",
          link: `${ADMIN}/${orgCode}/set-up-payment-shb/${schoolCode}/${ID}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );

      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail, ID, orgCode, schoolCode]);

  return (
    <>
      <Box pb={5}>
        <Flex
          alignItems={"center"}
          cursor="pointer"
          w={"fit-content"}
          onClick={() =>
            history.push(
              `${ADMIN}/${schoolDetail.orgCode}/tgtt-partner/${schoolDetail.schoolCode}`
            )
          }
        >
          <ChevronLeftIcon color={"#045993"} />
          <Text
            color={"#045993"}
            fontSize="15px"
            fontWeight={600}
            textDecorationLine="underline"
            w={"fit-content"}
          >
            Quay lại
          </Text>
        </Flex>
      </Box>
      <Box
        mt={10}
        p={{ base: "10px", md: "10px" }}
        minH="calc(100vh - 140px)"
        background={globalStyles.colors.bg_color[200]}
        borderRadius="6px"
        borderTopLeftRadius={0}
        position="relative"
      >
        <TabsPanel activeBtn={activeBtn} setActiveBtn={setActiveBtn} />
        {activeBtn === 1 && (
          <>
            <Box>
              <Text
                color={globalStyles.colors.text[202027]}
                fontSize="14px"
                fontWeight="600"
                my="3"
                mt="12"
              >
                Thiết lập TID
              </Text>

              <SetUpID
                listTID={listTID}
                setListTID={setListTID}
                paymentPartnerDetail={paymentPartnerDetail}
                handleGetDetailPaymentParner={handleGetDetailPaymentParner}
              />
            </Box>
          </>
        )}
        {activeBtn === 2 && (
          <Box>
            {paymentPartnerDetail ? (
              <>
                <Text
                  color={globalStyles.colors.text[202027]}
                  fontSize="14px"
                  fontWeight="600"
                  my="3"
                  mt="5"
                >
                  Thiết lập điều hướng giao dịch
                </Text>

                <TransactionNavigation
                  listTID={listTID}
                  setTransactionNavigation={setTransactionNavigation}
                  transactionNavigation={transactionNavigation}
                  typeFee={typeFee}
                  paymentPartnerDetail={paymentPartnerDetail}
                  handleGetDetailPaymentParner={handleGetDetailPaymentParner}
                  listClientCode={listClientCode}
                />
              </>
            ) : (
              <Box mt="100">
                <Empty />
              </Box>
            )}
          </Box>
        )}
        {/* {!paymentPartnerDetail && activeBtn !== 2 && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="center"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              style={{
                width: "95px",
              }}
              onClick={() =>
                history.push(
                  `${ADMIN}/${schoolDetail.orgCode}/tgtt-partner/${schoolDetail.schoolCode}`
                )
              }
            >
              Quay lại
            </Button>
            <Box ml="2">
              <MainButton onClick={onFinish} text="Xác nhận" />
            </Box>
          </Flex>
        )} */}
      </Box>
    </>
  );
};

export default SetUpPayment;
