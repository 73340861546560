import { NetWorkService } from "util/API";
// import { NetWorkServiceUpload } from "util/API_UPLOAD";
import {
  ChangeFeeConfig,
  IPartnersResponse,
  ItypeFeeResponse,
  ListOrganizationResponsive,
  OrganizationDetailResponsive,
  PaymentConfigNavigation,
  ResponFeeConfig,
  ResponseFeeConfig,
  ResponseListClientCode,
  ResponseListFeeConfig,
  ResponseListPaymentIntermediaries,
  ResponsePaymentMethodDetail,
  ResponsePaymentPartnerDetail,
  SchoolDetailResponsive,
} from "./Organization";

export const ORGANIZATION = "/organization";
const ORGANIZATION_CREATE = "/organization/create";
const SCHOOL = "/school";

const { Get, Post, PATCH, DELETE } = NetWorkService;

export default class OrganizationAdminService {
  static GetListOrganizationAdmin = async (query: string) => {
    return await Get<ListOrganizationResponsive>({
      url: ORGANIZATION + query,
    });
  };
  static CreateOrganization = async (data: any) => {
    return await Post<any>({
      url: ORGANIZATION_CREATE,
      body: { ...data },
    });
  };
  static CreateSchool = async (id: string, data: any) => {
    return await Post<any>({
      url: `/organization/${id}/school/create`,
      body: { ...data },
    });
  };
  static UpdateSchool = async (
    orgCode: string,
    schoolCode: string,
    data: any
  ) => {
    return await Post<SchoolDetailResponsive>({
      url: `/organization/${orgCode}/school/${schoolCode}/update`,
      body: { ...data },
    });
  };
  static GetSchool = async (id: string, query: string) => {
    return await Get<any>({
      url: ORGANIZATION + `/${id}` + SCHOOL + query,
    });
  };
  static OrganizationDetail = async (id: string) => {
    return await Get<OrganizationDetailResponsive>({
      url: ORGANIZATION + `/${id}/detail`,
    });
  };
  static OrganizationUpdate = async (id: string, data: any) => {
    return await Post<OrganizationDetailResponsive>({
      url: `/organization/${id}/update`,
      body: { ...data },
    });
  };
  static GetDetailShool = async (orgCode: string, schoolCode: string) => {
    return await Get<SchoolDetailResponsive>({
      url: `/organization/${orgCode}/school/${schoolCode}/detail`,
    });
  };
  static PaymentPartnerCreate = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/create`,
      body: { ...data },
    });
  };

  // Tạo mới config Vietinbank
  static PaymentConfigPartnerCreate = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/config/create`,
      body: { ...data },
    });
  };

  static PaymentVNPayPartnerCreate = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/config/create`,
      body: { ...data },
    });
  };
  static PaymentVNPayPartnerUpdate = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/config/update`,
      body: { ...data },
    });
  };
  static PaymentVNPayPartnerDelete = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await DELETE<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/config/delete`,
      body: { ...data },
    });
  };
  static PaymentPartnerUpdate = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/update`,
      body: { ...data },
    });
  };
  static PaymentPartnerDetail = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string
  ) => {
    return await Get<ResponsePaymentPartnerDetail>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/detail`,
    });
  };
  static PaymentMethodDetail = async (orgCode: string, schoolCode: string) => {
    return await Get<ResponsePaymentMethodDetail>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentMethod/detail`,
    });
  };
  static PaymentMethodUpdate = async (
    orgCode: string,
    schoolCode: string,
    data: any
  ) => {
    return await Post<ResponsePaymentMethodDetail>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentMethod/update`,
      body: { ...data },
    });
  };
  // Lấy thông danh sách loại thu phí của trường
  static GetSchoolTypeFee = async (orgCode: string, schoolCode: string) => {
    return await Get<ItypeFeeResponse>({
      url: `/organization/${orgCode}/school/${schoolCode}/typeFee`,
    });
  };

  // Lấy danh sách đối tác thanh toán
  static GetSchoolPartner = async (orgCode: string, schoolCode: string) => {
    return await Get<IPartnersResponse>({
      url: `/organization/${orgCode}/school/${schoolCode}/partner`,
    });
  };

  static DETAIL_BRANCH_LIST = async (orgCode: string) => {
    return await Get<any>({
      url: `/organization/${orgCode}/branch`,
    });
  };

  static GET_ALL_SCHOOL = async () => {
    return await Get<any>({
      url: `${SCHOOL}`,
    });
  };

  // Gọi lại gạch nợ
  static RETRY_DEPBRICK = async (
    orgCode: string,
    schoolCode: string,
    id: string
  ) => {
    return await Get<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/retry-deptBrick/${id}`,
    });
  };

  //Lấy thông tin danh sách cấu hình phí
  static GET_LIST_FEE = async (orgCode: string, schoolCode: string) => {
    return await Get<ResponseListFeeConfig>({
      url: `organization/${orgCode}/school/${schoolCode}/fee/list`,
    });
  };

  //Lấy thông tin chi tiết cấu hình phí
  static GET_FEE = async (orgCode: string, schoolCode: string) => {
    return await Get<ResponseFeeConfig>({
      url: `organization/${orgCode}/school/${schoolCode}/fee`,
    });
  };

  //Tạo cấu hình phí
  static FeeConfig = async (
    orgCode: string,
    schoolCode: string,
    data: ChangeFeeConfig
  ) => {
    return await Post<ResponFeeConfig>({
      url: `organization/${orgCode}/school/${schoolCode}/fee/create`,
      body: { ...data },
    });
  };

  //Update Cấu hình phí
  static UpdateFeeConfig = async (
    orgCode: string,
    schoolCode: string,
    data: ChangeFeeConfig
  ) => {
    return await Post<ResponFeeConfig>({
      url: `organization/${orgCode}/school/${schoolCode}/fee/update`,
      body: { ...data },
    });
  };

  // update star thiết lâị TID
  static UpdateStarSettingTID = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string,
    data: any
  ) => {
    return await Post<any>({
      url: `organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/tidStar/update`,
      body: { ...data },
    });
  };

  //Lấy thông tin danh sách cấu hình phí
  static GetPaymentIntermediaries = async (
    orgCode: string,
    schoolCode: string
  ) => {
    return await Get<ResponseListPaymentIntermediaries>({
      url: `organization/${orgCode}/school/${schoolCode}/partner`,
    });
  };

  //Lấy danh sách clientCode
  static GetListClientCode = async (orgCode: string, schoolCode: string) => {
    return await Get<ResponseListClientCode>({
      url: `organization/${orgCode}/school/${schoolCode}/clientCode`,
    });
  };

  //Đối soát giao dịch
  static ReconcileCompare = async (
    orgCode: string,
    schoolCode: string,
    data: { fileId: string; date: number }
  ) => {
    return await Post({
      url: `organization/${orgCode}/school/${schoolCode}/reconcile/compare`,
      body: { ...data },
    });
  };

  //Đối soát giao dịch !== VNPAY
  static ReconcileCompareNotVnpay = async (
    orgCode: string,
    schoolCode: string,
    data: { configCode: string; date: string; paymentPartner: string }
  ) => {
    return await Post({
      // url: `/organization/dhvb/school/dhvb/reconcile-transaction`,
      url: `/organization/${orgCode}/school/${schoolCode}/reconcile-transaction`,
      body: { ...data },
    });
  };

  // Lay config theo Partner bidv + onepay + ACB
  static PaymentConfigPartner = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string
  ) => {
    return await Get<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/${paymentPartner}`,
    });
  };

  // Set config cho BIDV + Onepay
  static AddPartnerConfig = async (
    data: any,
    orgCode: string,
    schoolCode: string,
    paymentPartner: string
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/${paymentPartner}`,
      body: { ...data },
    });
  };
  // Update BIDV + ONEPAY Config
  static PartnerUpdateConfig = async (
    data: any,
    orgCode: string,
    schoolCode: string,
    configCode: string,
    paymentPartner: string
  ) => {
    return await PATCH<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/${paymentPartner}/${configCode}`,
      body: { ...data },
    });
  };

  // Lay config theo Partner BIDV + ONEPAY
  static GetPaymentConfigNavigation = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string
  ) => {
    return await Get<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/navigation/${paymentPartner}`,
    });
  };

  // Tao nav config
  static CreatePaymentNavigation = async (
    data: PaymentConfigNavigation,
    orgCode: string,
    schoolCode: string
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/navigation`,
      body: { ...data },
    });
  };
  // Update NAV config
  static UpdatePaymentNavigation = async (
    data: PaymentConfigNavigation,
    orgCode: string,
    schoolCode: string,
    id: string
  ) => {
    return await PATCH<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/navigation/${id}`,
      body: { ...data },
    });
  };
  // Del payment nav
  static DelPaymentConfig = async (
    orgCode: string,
    schoolCode: string,
    id: string
  ) => {
    return await DELETE<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/${id}`,
    });
  };

  // Del Nav config
  static DelPaymentNavigation = async (
    orgCode: string,
    schoolCode: string,
    id: string
  ) => {
    return await DELETE<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/config/payment/navigation/${id}`,
    });
  };

  // Export báo cáo theo ngày
  static ReportDaily = async (
    orgCode: string,
    schoolCode: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/reconcile/report/daily`,
      body: { ...data },
    });
  };

  // Export báo cáo giao dịch tổng hợp
  static ReportSummaryTrans = async (
    orgCode: string,
    schoolCode: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/reconcile/report/summary-trans`,
      body: { ...data },
    });
  };

  // Lấy thông tin chi tiết đối tác/trung gian thanh toán
  static GetPaymentConfigDetail = async (
    orgCode: string,
    schoolCode: string,
    paymentPartner: string
  ) => {
    return await Get<any>({
      url: `/organization/${orgCode}/school/${schoolCode}/paymentPartner/${paymentPartner}/detail`,
    });
  };
}
