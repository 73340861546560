import { Box, Flex, Text } from "@chakra-ui/react";
import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPartners } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import {
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  setSchoolDetail,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import ItemParner from "./Components/ItemParner";
import TabsPanel from "./Components/TabsPanel";
import * as Sentry from "@sentry/browser";

export const LIST_TTGT = [
  "VNPAY",
  "BIDV",
  "SHB",
  "ONEPAY",
  "VIETINBANK",
  "ACB",
];

const TGTTPartner = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);

  const [listTgtt, setListTgtt] = useState<IPartners[]>([]);

  const { GetDetailShool, GetSchoolPartner } = OrganizationAdminService;

  const handleGetDetail = useCallback(async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetDetailShool(orgCode, ID);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        dispatch(setSchoolDetail(response.data));
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  }, [ID, orgCode, GetDetailShool, dispatch]);

  const GetListTgtt = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolPartner(orgCode, ID);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setListTgtt(
          res.data.data.partners.map((e) => ({
            ...e,
            isPartner: LIST_TTGT.includes(e.partnerCode),
          }))
        );
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureException("Có lỗi xảy ra");
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    !schoolDetail && handleGetDetail();
    GetListTgtt();
    // eslint-disable-next-line
  }, [handleGetDetail]);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${schoolDetail.orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/school-information/${schoolDetail.schoolCode}`,
        },
        {
          name: "Đối tác TGTT",
          link: `${ADMIN}/${schoolDetail.orgCode}/tgtt-partner/${schoolDetail.schoolCode}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );

      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={3} ID={ID} orgCode={orgCode} />
      <Box>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight="600"
          my="3"
        >
          Thiết lập đối tác trung gian thanh toán
        </Text>
        <Text
          my="3"
          fontWeight={300}
          fontSize="13px"
          fontStyle={"italic"}
          color="#000"
          opacity={"0.5"}
        >
          *Đối tác trung gian thanh toán sẽ được hiển thị trên cổng thanh toán
          AQ Bill Gateway. Nếu không có đối tác được chọn, hệ thống sẽ hiển thị
          toàn bộ đối tác của cổng thanh toán.
        </Text>

        <Flex flexWrap={"wrap"}>
          {listTgtt.map((e, i) => (
            <ItemParner
              key={i}
              schoolCode={ID}
              type={e.status}
              orgCode={orgCode}
              id={e.partnerCode}
              isParner={e.isPartner}
              img={e.partnerCode}
              showCheck={e.status ? true : false}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default TGTTPartner;
