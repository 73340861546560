// Admin Imports
import DataTables from "views/admin/UserManager";
import SignInCentered from "views/auth/signIn";
// Auth Imports
import Login from "views/auth/Login/Login";
import OrganizationManagement from "views/admin/OrganizationManagement";
import OrganizationDetail from "views/admin/OrganizationDetail";
import UserManagerDetail from "views/admin/UserManagerDetail";
import OperationLogOsa from "views/osa/OperationLog";
import Report from "views/admin/Report";
import ReportOsa from "views/osa/Report";
import BranchManagement from "views/osa/BranchManagement";
import ManagementTrainingFacilities from "views/osa/ManagementTrainingFacilities";
import OrganizationalUserManagement from "views/osa/OrganizationalUserManagement";
import OperationLog from "views/admin/OperationLog";
import { ADMIN, AUTH, OSA, CSDT } from "util/Constant";
import AddNewOrganization from "views/admin/AddNewOrganization";
import TrainingFacilities from "views/admin/AddNewOrganization/TrainingFacilities";
import LoginAccount from "views/admin/AddNewOrganization/LoginAccount";
import Receipts from "views/admin/Receipts";
import BasicInformation from "views/admin/CSDT/BasicInformation";
import BasicInformationDetail from "views/admin/CSDTDetail/BasicInformation";
import LoginAccountShool from "views/admin/CSDT/LoginAccountShool";
import LoginAccountShoolDetail from "views/admin/CSDTDetail/LoginAccountShool";
import TGTTPartnerDetail from "views/admin/CSDTDetail/TGTTPartner";
import PaymentMethodsDetail from "views/admin/CSDTDetail/PaymentMethods";
import ManagementFeesDetal from "views/admin/CSDTDetail/ManagementFees";
import SetUpPaymentVNPAY from "views/admin/SetUpPaymentVNPAY";
import StudentManagement from "views/csdt/StudentManagement";
import BranchDetal from "views/osa/BranchDetal";
import TransactionManagement from "views/admin/TransactionManagement";
import TypesOfTaxes from "views/osa/TypesOfTaxes";
import BillType from "views/admin/CSDT/BillType";
import ReceiptsOSA from "views/csdt/ReceiptsOSA";
import DebtBrickHistory from "views/admin/DebtBrickHistory";
import RefundHistory from "views/csdt/RefundHistory";
import RequestRefund from "views/csdt/RequestRefund";
import RefundManagement from "views/csdt/RefundManagement";
import StudenGroup from "views/csdt/StudenGroup";
import DetailStudent from "views/csdt/DetailStudent";
import ReconciliationManagement from "views/csdt/ReconciliationManagement";
import UserInfor from "views/admin/UserInfor";
import UserInforOSA from "views/osa/UserInforOSA";
import UserInforCSDT from "views/csdt/UserInforCSDT";
import CSDTUserManagement from "views/csdt/CSDTUserManagement";
import SetUpPaymentBIDV from "views/admin/SetUpPaymentBIDV";
import SetUpPaymentONEPAY from "views/admin/SetUpPaymentONEPAY";
import SetUpPaymentSHB from "views/admin/SetUpPaymentSHB";
import SetUpPaymentICB from "views/admin/SetUpPaymentICB";
import SetUpPaymentACB from "views/admin/SetUpPaymentACB";
import TransactionDetail from "views/admin/TransactionDetail";
import ReceiptsDetail from "views/admin/ReceiptsDetail";
import DebtBrickHistoryDetail from "views/admin/DebtBrickHistoryDetail";

const routes = [
  // ------ ADMIN EDUBILLS (ROOT USER) ------- //
  {
    name: "Quản lý tổ chức",
    layout: `${ADMIN}`,
    path: "/organization-management",
    component: OrganizationManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý người dùng EduBills",
    layout: `${ADMIN}`,
    path: "/user-management",
    component: DataTables,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý người dùng EduBills",
    layout: `${ADMIN}`,
    path: "/add-user-edubills",
    component: UserInfor,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý người dùng EduBills",
    layout: `${ADMIN}`,
    path: "/update-user-edubills/:id",
    component: UserInfor,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý phiếu thu",
    layout: `${ADMIN}`,
    path: "/receipts",
    component: Receipts,
    icon: "fa-chart-column",
  },
  {
    name: "Chi tiết phiếu thu",
    layout: `${ADMIN}`,
    path: "/receipt/:id",
    component: ReceiptsDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Lịch sử gạch nợ",
    layout: `${ADMIN}`,
    path: "/instant-payment-notification",
    component: DebtBrickHistory,
    icon: "fa-chart-column",
  },
  {
    name: "Chi tiết lịch sử gạch nợ",
    layout: `${ADMIN}`,
    path: "/instant-payment-notification-detail/:id",
    component: DebtBrickHistoryDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý giao dịch",
    layout: `${ADMIN}`,
    path: "/transaction-management",
    component: TransactionManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Chi tiết giao dịch",
    layout: `${ADMIN}`,
    path: "/transaction-details/:id",
    component: TransactionDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Chi tiết tổ chức",
    layout: `${ADMIN}`,
    path: "/organization-detail/:id",
    component: OrganizationDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Chi tiết tổ chức",
    layout: `${ADMIN}`,
    path: "/user-detail/:id",
    component: UserManagerDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  // === them moi to chuc ===== //
  {
    name: "Quản lý tổ chức",
    path: "/add-new-organization/:id",
    layout: `${ADMIN}`,
    component: AddNewOrganization,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/training-facilities/:id",
    layout: `${ADMIN}`,
    component: TrainingFacilities,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/login-account/:id",
    layout: `${ADMIN}`,
    component: LoginAccount,
    icon: "fa-chart-column",
    hidden: true,
  },

  // === end them moi to chuc ===== //

  // ======= co so (them moi) ======== //
  {
    name: "Quản lý tổ chức",
    path: "/:id/add-school-information/:id",
    layout: `${ADMIN}`,
    component: BasicInformation,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/bill-type/:id",
    layout: `${ADMIN}`,
    component: BillType,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/add-tgtt-partner/:id",
    layout: `${ADMIN}`,
    component: TGTTPartnerDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/add-payment-methods/:id",
    layout: `${ADMIN}`,
    component: PaymentMethodsDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/add-management-fees/:id",
    layout: `${ADMIN}`,
    component: ManagementFeesDetal,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/add-login-shool/:id",
    layout: `${ADMIN}`,
    component: LoginAccountShool,
    icon: "fa-chart-column",
    hidden: true,
  },
  // ========= end co so(them moi) ========= //

  // ======= co so (Chỉnh sửa) ==============//
  {
    name: "Quản lý tổ chức",
    path: "/:id/school-information/:id",
    layout: `${ADMIN}`,
    component: BasicInformationDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/tgtt-partner/:id",
    layout: `${ADMIN}`,
    component: TGTTPartnerDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/payment-methods/:id",
    layout: `${ADMIN}`,
    component: PaymentMethodsDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/management-fees/:id",
    layout: `${ADMIN}`,
    component: ManagementFeesDetal,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý tổ chức",
    path: "/:id/login-shool/:id",
    layout: `${ADMIN}`,
    component: LoginAccountShoolDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  // ========= end co so(Chỉnh sửa) ========= //

  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-vnpay/:id/:id",
    component: SetUpPaymentVNPAY,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-bidv/:id/:id",
    component: SetUpPaymentBIDV,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-onepay/:id/:id",
    component: SetUpPaymentONEPAY,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-acb/:id/:id",
    component: SetUpPaymentACB,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-shb/:id/:id",
    component: SetUpPaymentSHB,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Thiết lập thanh toán",
    layout: `${ADMIN}`,
    path: "/:id/set-up-payment-vietinbank/:id/:id",
    component: SetUpPaymentICB,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Nhật ký thao tác",
    layout: `${ADMIN}`,
    path: "/operation-log",
    component: OperationLog,
    icon: "fa-chart-column",
  },
  {
    name: "Báo cáo",
    layout: `${ADMIN}`,
    path: "/report",
    component: Report,
    icon: "fa-chart-column",
  },

  // --------------- osa --------------- //

  {
    name: "Quản lý chi nhánh",
    layout: `${OSA}`,
    path: "/branch-detail/:id",
    component: BranchDetal,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý chi nhánh",
    layout: `${OSA}`,
    path: "/branch-management",
    component: BranchManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý cơ sở đào tạo",
    layout: `${OSA}`,
    path: "/management-training-facilities",
    component: ManagementTrainingFacilities,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý người dùng tổ chức",
    layout: `${OSA}`,
    path: "/user-management",
    component: OrganizationalUserManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý người dùng tổ chức",
    layout: `${OSA}`,
    path: "/add-user",
    component: UserInforOSA,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý người dùng tổ chức",
    layout: `${OSA}`,
    path: "/:id/update-user-infor/:id",
    component: UserInforOSA,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Nhật ký thao tác",
    layout: `${OSA}`,
    path: "/operation-log-osa",
    component: OperationLogOsa,
    icon: "fa-chart-column",
  },
  {
    name: "Báo cáo",
    layout: `${OSA}`,
    path: "/report",
    component: ReportOsa,
    icon: "fa-chart-column",
  },

  // --------------- auth --------------- //
  {
    name: "sign in",
    layout: `${AUTH}`,
    path: "/sign-in",
    component: SignInCentered,
    icon: "fa-chart-column",
  },
  {
    name: "login",
    layout: `${AUTH}`,
    path: "/login",
    component: Login,
    icon: "fa-chart-column",
  },

  // --------------- CSDT --------------- //

  {
    name: "Quản lý người dùng",
    layout: `${CSDT}`,
    path: "/user-management",
    component: CSDTUserManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Quản lý người dùng",
    layout: `${CSDT}`,
    path: "/add-user",
    component: UserInforCSDT,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý người dùng",
    layout: `${CSDT}`,
    path: "/:id/update-user-infor/:id",
    component: UserInforCSDT,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý sinh viên",
    layout: `${CSDT}`,
    path: "/student-management",
    component: StudentManagement,
    icon: "fa-chart-column",
    children: [
      {
        name: "Danh sách sinh viên",
        layout: `${CSDT}`,
        path: "/student-management",
        component: StudentManagement,
        icon: "fa-chart-column",
      },
      {
        name: "Danh sách nhóm sinh viên",
        layout: `${CSDT}`,
        path: "/student-group",
        component: StudenGroup,
        icon: "fa-chart-column",
      },
    ],
  },

  {
    name: "Quản lý phiếu thu",
    layout: `${CSDT}`,
    path: "/receipts",
    component: ReceiptsOSA,
    icon: "fa-chart-column",
    children: [
      {
        name: "Danh sách phiếu thu",
        layout: `${CSDT}`,
        path: "/receipts",
        component: ReceiptsOSA,
        icon: "fa-chart-column",
      },
      {
        name: "Danh mục loại thu",
        layout: `${CSDT}`,
        path: "/bill-type",
        component: TypesOfTaxes,
        icon: "fa-chart-column",
      },
    ],
  },
  {
    name: "Chi tiết phiếu thu",
    layout: `${CSDT}`,
    path: "/receipt/:id",
    component: ReceiptsDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  // {
  //   name: "Quản lý lô phiếu thu",
  //   layout: `${CSDT}`,
  //   path: "/manage-batch-receipts",
  //   component: ManageBatchReceipts,
  //   icon: "fa-chart-column",
  // },
  {
    name: "Lịch sử gạch nợ",
    layout: `${CSDT}`,
    path: "/instant-payment-notification",
    component: DebtBrickHistory,
    icon: "fa-chart-column",
  },
  {
    name: "Chi tiết lịch sử gạch nợ",
    layout: `${CSDT}`,
    path: "/instant-payment-notification-detail/:id",
    component: DebtBrickHistoryDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý giao dịch",
    layout: `${CSDT}`,
    path: "/transaction-management",
    component: TransactionManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Chi tiết giao dịch",
    layout: `${CSDT}`,
    path: "/transaction-details/:id",
    component: TransactionDetail,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Quản lý hoàn tiền",
    layout: `${CSDT}`,
    path: "/refund-history",
    component: RefundHistory,
    icon: "fa-chart-column",
    children: [
      {
        name: "Lịch sử hoàn tiền",
        layout: `${CSDT}`,
        path: "/refund-history",
        component: RefundHistory,
        icon: "fa-chart-column",
      },
      {
        name: "Yêu cầu hoàn tiền",
        layout: `${CSDT}`,
        path: "/request-refund",
        component: RequestRefund,
        icon: "fa-chart-column",
      },
    ],
  },
  {
    name: "Quản lý đối soát",
    layout: `${CSDT}`,
    path: "/reconciliation-management",
    component: ReconciliationManagement,
    icon: "fa-chart-column",
  },
  {
    name: "Nhật ký thao tác",
    layout: `${CSDT}`,
    path: "/operation-log-ssa",
    component: OperationLogOsa,
    icon: "fa-chart-column",
  },
  {
    name: "Báo cáo",
    layout: `${CSDT}`,
    path: "/report",
    component: ReportOsa,
    icon: "fa-chart-column",
  },
  {
    name: "Danh mục loại thu",
    layout: `${CSDT}`,
    path: "/bill-type",
    component: TypesOfTaxes,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Yêu cầu hoàn tiền",
    layout: `${CSDT}`,
    path: "/request-refund",
    component: RequestRefund,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Yêu cầu hoàn tiền",
    layout: `${CSDT}`,
    path: "/refund-management",
    component: RefundManagement,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Danh sách nhóm sinh viên",
    layout: `${CSDT}`,
    path: "/student-group",
    component: StudenGroup,
    icon: "fa-chart-column",
    hidden: true,
  },
  {
    name: "Danh sách sinh viên",
    layout: `${CSDT}`,
    path: "/student-information/:id",
    component: DetailStudent,
    icon: "fa-chart-column",
    hidden: true,
  },
];

export default routes;
